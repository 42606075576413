<template>
  <g
    @click="$emit('click')"
    :class="{
      'cursor-grab': draggable,
    }"
    >
    <rect
      :x="location.x"
      :y="location.y"
      :width="location.width"
      :height="location.depth"
      :stroke="strokeColour"
      :fill="fillColour"
      stroke-width="15"
      :class="className"
    ></rect>
    <text
      v-show="!isDragging"
      :x="location.x + 20"
      :y="location.y + 40"
      font-size="3em"
      dominant-baseline="middle"
      text-anchor="start"
      text-rendering="geometricPrecision"
      :fill="textColour"
      :class="textClassName"
    >
      {{ location.number }}
    </text>
    <text
      v-show="!isDragging"
      :x="location.centre_x"
      :y="location.centre_y"
      font-size="3em"
      dominant-baseline="middle"
      text-anchor="middle"
      text-rendering="geometricPrecision"
      :fill="textColour"
      :class="textClassName"
    >
      {{ location.name }}
    </text>
  </g>
</template>

<script setup>
import { computed } from 'vue';

defineEmits(['click']);

const props = defineProps({
  location: Object,
  overrideStrokeColour: String,
  overrideFillColour: String,
  overrideTextColour: String,
  defaultPalette: Object,
  draggable: Boolean,
  isDragging: Boolean,
  className: String,
  textClassName: String,
});

const strokeColour = computed(() => {
  if (props.overrideStrokeColour) {
    return props.overrideStrokeColour;
  }
  if (props.location.stroke_colour) {
    return props.location.stroke_colour;
  }

  return props.defaultPalette[`${props.location.palette_key}_border_colour`];
});

const fillColour = computed(() => {
  if (props.overrideFillColour) {
    return props.overrideFillColour;
  }
  if (props.location.fill_colour) {
    return props.location.fill_colour;
  }

  return props.defaultPalette[`${props.location.palette_key}_fill_colour`];
});

const textColour = computed(() => {
  if (props.overrideTextColour) {
    return props.overrideTextColour;
  }
  if (props.location.text_colour) {
    return props.location.text_colour;
  }

  return props.defaultPalette[`${props.location.palette_key}_text_colour`];
});

</script>
