<template>
  <div class="floorplan">
    <div
      style="position: absolute; inset: 0"
      ref="container"
      :class="{ 'cursor-not-allowed': lockSelected }"
    >
      <svg
        v-if="floorplan"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        :viewBox="`${viewBox[0]} ${viewBox[1]} ${viewBox[2]} ${viewBox[3]}`"
        ref="svg"
      >
        <image
          x="0"
          y="0"
          :width="floorplan.width"
          :href="floorplan.background_url"
        />

        <FloorplanGrid
          v-if="showGrid"
          :floorplanWidth="floorplan.width"
          :floorplanDepth="floorplan.depth"
          :roundingFactor="floorplan.rounding_factor"
        />

        <FeFloorplanStand
          v-show="location.id !== selectedLocation?.id"
          v-for="location in floorplan.locations"
          :key="location.id"
          :location="location"
          @click="isPanning ? null : locationClicked(location)"
          :defaultPalette="floorplan.palette"
        />
        <FeFloorplanStand
          :key="selectedLocation.id"
          v-if="selectedLocation"
          ref="selectedLocationEl"
          :location="selectedLocation"
          overrideStrokeColour="#000000"
          overrideFillColour="#1a24ff"
          overrideTextColour="#ffffff"
          :defaultPalette="floorplan.palette"
          :draggable="lockSelected"
          :isDragging="lockSelected && isDragging"
          :className="flashSelectedLocation ? 'fe-flash-selected' : ''"
          :textClassName="flashSelectedLocation ? 'fe-selected-text' : ''"
        />
        <g v-if="lockSelected && selectedLocation">
          <rect
            :x="selectedLocation.x + (selectedLocation.width / 2) - 50"
            :y="selectedLocation.y - 50"
            :width="100"
            :height="100"
            fill="#ffffff"
            stroke="#000000"
            stroke-width="5"
            ref="topHandle"
            class="cursor-row-resize"
          />
          <rect
            :x="selectedLocation.x + selectedLocation.width - 50"
            :y="selectedLocation.y + (selectedLocation.depth / 2) - 50"
            :width="100"
            :height="100"
            fill="#ffffff"
            stroke="#000000"
            stroke-width="5"
            ref="rightHandle"
            class="cursor-col-resize"
          />
          <rect
            :x="selectedLocation.x + (selectedLocation.width / 2) - 50"
            :y="selectedLocation.y + selectedLocation.depth - 50"
            :width="100"
            :height="100"
            fill="#ffffff"
            stroke="#000000"
            stroke-width="5"
            ref="bottomHandle"
            class="cursor-row-resize"
          />
          <rect
            :x="selectedLocation.x - 50"
            :y="selectedLocation.y + (selectedLocation.depth / 2) - 50"
            :width="100"
            :height="100"
            fill="#ffffff"
            stroke="#000000"
            stroke-width="5"
            ref="leftHandle"
            class="cursor-col-resize"
          />
        </g>
        <template v-if="showPaths">
          <FloorplanPath
            v-for="path in floorplan.paths"
            :key="path.id"
            :path="path"
            :overridePathColor="overridePathColor"
            @pathClicked="pathClicked"
          />

          <FloorplanPath
            v-if="selectedPath"
            ref="selectedPathEl"
            :key="selectedPath.id"
            :path="selectedPath"
            overridePathColor="#000000"
          />

          <g v-if="lockSelected && selectedPath">
            <circle
              :cx="selectedPath.x1"
              :cy="selectedPath.y1"
              r="48"
              fill="#ffffff"
              stroke="#000000"
              stroke-width="5"
              ref="pathHandle1"
              class="cursor-move"
            />
            <circle
              :cx="selectedPath.x2"
              :cy="selectedPath.y2"
              r="48"
              fill="#ffffff"
              stroke="#000000"
              stroke-width="5"
              ref="pathHandle2"
              class="cursor-move"
            />
          </g>
        </template>

        <template v-if="heatMap.length > 0">
          <defs>
            <radialGradient id="meeting800">
              <stop offset="50%" stop-color="#0001de" stop-opacity="1" />
              <stop offset="90%" stop-color="#0001de" stop-opacity="0" />
            </radialGradient>
            <radialGradient id="meeting600">
              <stop offset="50%" stop-color="#0200ff" stop-opacity="1" />
              <stop offset="90%" stop-color="#0200ff" stop-opacity="0" />
            </radialGradient>
            <radialGradient id="meeting500">
              <stop offset="50%" stop-color="#1a24ff" stop-opacity="1" />
              <stop offset="90%" stop-color="#1a24ff" stop-opacity="0" />
            </radialGradient>
            <radialGradient id="meeting400">
              <stop offset="50%" stop-color="#445dff" stop-opacity="1" />
              <stop offset="90%" stop-color="#445dff" stop-opacity="0" />
            </radialGradient>
          </defs>
          <TransitionGroup name="heatmap">
            <circle
              v-for="(item) in heatMap"
              :key="item.id"
              :cx="item.cx"
              :cy="item.cy"
              :r="item.r"
              :fill="`url('#${item.mapType}${item.colourScale}')`"
              stroke-width="5"
            />
          </TransitionGroup>

        </template>

        <FloorplanRoute
          v-if="activePath?.length"
          :activePath="activePath"
        />
      </svg>
      <div class="floorplan__coords bg-rock-200/50" v-if="showCoords && (svgX !== null && svgY !== null)">
        <i class="fas fa-compass me-1"></i>
        {{ (svgX / floorplan.rounding_factor).toFixed(2) }},
        {{ (svgY / floorplan.rounding_factor).toFixed(2) }}
      </div>
    </div>

  </div>

</template>

<script setup>
import {
  ref, watch, toRefs, onMounted, nextTick,
} from 'vue';
import { useEventListener, useElementSize } from '@vueuse/core';
import FeFloorplanStand from './FeFloorplanStand.vue';
import FloorplanGrid from './FloorplanGrid.vue';
import FloorplanPath from './FloorplanPath.vue';
import FloorplanRoute from './FloorplanRoute.vue';

const props = defineProps({
  floorplan: Object,
  showGrid: Boolean,
  showPaths: Boolean,
  showCoords: Boolean,
  width: Number,
  height: Number,
  lockSelected: Boolean,
  activePath: {
    type: Array,
    default: () => [],
  },
  selectedLocation: Object,
  flashSelectedLocation: Boolean,
  overridePathColor: String,
  selectedPath: Object,
  heatMap: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits([
  'svgReady',
  'svgClicked',
  'contentMenuOpened',
  'hover',
  'locationClicked',
  'locationDblClicked',
  'locationMove',
  'locationMoved',
  'locationResize',
  'locationResized',
  'pathClicked',
  'pathDblClicked',
  'pathNodeMove',
  'pathNodeMoved',
]);

// State & References Initialization
const { floorplan } = toRefs(props);
const container = ref(null);
const svg = ref(null); // Declare svg ref to access its methods and properties
const viewBox = ref([0, 0, 0, 0]);
const svgX = ref(null);
const svgY = ref(null);
const { width: containerWidth, height: containerHeight } = useElementSize(container);

let initialDistance = null;
let initialViewBoxState = null;
let isPinching = false; // Flag to indicate transition phase after pinch

const minZoom = 0.05;
const maxZoom = 2.0;

// States for interactions
let isPanning = false;
let isDragging = false;
let isResizing = false;
let isPathNodeMoving = false;

// Refs for selected elements
const selectedLocationEl = ref(null);
const topHandle = ref(null);
const rightHandle = ref(null);
const bottomHandle = ref(null);
const leftHandle = ref(null);
const selectedPathEl = ref(null);
const pathHandle1 = ref(null);
const pathHandle2 = ref(null);

// Touch States
let touchTimeout; // Handle context menu for touch devices
let isLongPress = false; // Handle context menu for touch devices
let initialTouchX = 0;
let initialTouchY = 0;

const zoomSensitivity = 1; // Lower value for smoother zoom
let animationFrameId;

let initW = false;
let initH = false;

// Utility functions
const updateViewBox = (targetX, targetY, targetWidth, targetHeight, easeFactor) => {
  viewBox.value = [
    viewBox.value[0] + (targetX - viewBox.value[0]) * easeFactor,
    viewBox.value[1] + (targetY - viewBox.value[1]) * easeFactor,
    viewBox.value[2] + (targetWidth - viewBox.value[2]) * easeFactor,
    viewBox.value[3] + (targetHeight - viewBox.value[3]) * easeFactor,
  ];
};

const screenToSvgCoordinates = (x, y) => {
  const ctm = svg.value.getScreenCTM();
  if (!ctm) return { x: 0, y: 0 };
  return DOMPointReadOnly.fromPoint({ x, y })
    .matrixTransform(ctm.inverse());
};

const easeInOut = (progress) => 0.5 - Math.cos(progress * Math.PI) / 2;

// Animation functions
const animateTransition = (targetX, targetY, targetWidth, targetHeight, duration = 1000) => {
  const startTime = performance.now();

  function step(time) {
    let progress = (time - startTime) / duration;
    progress = Math.min(1, progress);
    const easeFactor = easeInOut(progress);
    updateViewBox(targetX, targetY, targetWidth, targetHeight, easeFactor);
    if (progress < 1) requestAnimationFrame(step);
  }

  requestAnimationFrame(step);
};

// ViewBox related function
const resetViewBox = (animate = true) => {
  const multiplier = (containerWidth.value / containerHeight.value) * (floorplan.value.depth / floorplan.value.width);
  const viewportHeight = (1.1 * floorplan.value.depth) / Math.min(1, multiplier);
  const viewportWidth = 1.1 * floorplan.value.width * Math.max(1, multiplier);
  const startX = (viewportWidth - floorplan.value.width) / 2;
  const startY = (viewportHeight - floorplan.value.depth) / 2;

  if (animate) {
    animateTransition(-1 * startX, -1 * startY, viewportWidth, viewportHeight);
  } else {
    viewBox.value = [-1 * startX, -1 * startY, viewportWidth, viewportHeight];
  }
};

const locationClicked = (location) => {
  if (props.lockSelected) {
    return;
  }

  if (props.selectedLocation?.id === location.id) {
    return;
  }

  emit('locationClicked', location);
};

const pathClicked = (path) => {
  if (props.lockSelected) {
    return;
  }

  if (props.selectedPath?.id === path.id) {
    return;
  }

  emit('pathClicked', path);
};

// Interaction functions
const moveSelected = (movementX, movementY) => {
  const ctm = svg.value.getScreenCTM();
  const movement = new DOMMatrix([0, 0, movementX, movementY, 0, 0]).multiply(ctm.inverse());
  if (isDragging) {
    const { x, y } = props.selectedLocation;
    const newX = x + movement.c - movement.a;
    const newY = y + movement.d - movement.b;
    if (newX !== x || newY !== y) {
      emit('locationMove', { x: newX, y: newY });
    }
  }
};

const moveSelectedPathNode = (movementX, movementY) => {
  const ctm = svg.value.getScreenCTM();
  const movement = new DOMMatrix([0, 0, movementX, movementY, 0, 0]).multiply(ctm.inverse());
  if (isPathNodeMoving) {
    const x = isPathNodeMoving === 'first' ? props.selectedPath.x1 : props.selectedPath.x2;
    const y = isPathNodeMoving === 'first' ? props.selectedPath.y1 : props.selectedPath.y2;
    const newX = x + movement.c - movement.a;
    const newY = y + movement.d - movement.b;
    if (newX !== x || newY !== y) {
      emit('pathNodeMove', {
        node: isPathNodeMoving,
        x: newX,
        y: newY,
      });
    }
  }
};

const zoomToLocation = (location) => {
  // Calculate the scale to fit the target area into the container
  const scaleX = containerWidth.value / location.width;
  const scaleY = containerHeight.value / location.depth;
  const scale = Math.min(scaleX, scaleY);

  // Calculate the new viewBox dimensions
  const viewBoxWidth = (containerWidth.value / scale) * 2;
  const viewBoxHeight = (containerHeight.value / scale) * 2;

  // Calculate the viewBox's top-left coordinates
  const viewBoxX = location.centre_x - (viewBoxWidth / 2);
  const viewBoxY = location.centre_y - (viewBoxHeight / 2);

  // Ensure the viewBox stays within the bounds of the SVG
  const clampedViewBoxX = Math.max(0, Math.min(viewBoxX, floorplan.value.width - viewBoxWidth));
  const clampedViewBoxY = Math.max(0, Math.min(viewBoxY, floorplan.value.depth - viewBoxHeight));

  animateTransition(clampedViewBoxX, clampedViewBoxY, viewBoxWidth, viewBoxHeight);
};

const zoomToExtents = (x1, y1, x2, y2, factor = 1.9) => {
  const multiplier = (containerWidth.value / containerHeight.value) * (floorplan.value.depth / floorplan.value.width);

  // Exit if the multiplier is invalid or zero
  if (!Number.isFinite(multiplier) || multiplier === 0) return;

  // Calculate the minimum and maximum extents
  const minX = Math.min(x1, x2);
  const maxX = Math.max(x1, x2);
  const minY = Math.min(y1, y2);
  const maxY = Math.max(y1, y2);

  // Determine the new width and height
  let newWidth; let newHeight;
  if ((maxX - minX) > (maxY - minY)) {
    newWidth = (maxX - minX) * factor;
    newHeight = newWidth / multiplier;
  } else {
    newHeight = (maxY - minY) * factor;
    newWidth = newHeight * multiplier;
  }

  // Find the centre of the bounding box
  const centreX = minX + ((maxX - minX) / 2);
  const centreY = minY + ((maxY - minY) / 2);

  // Transition the viewBox to fit the new extents
  animateTransition(
    centreX - newWidth / 2,
    centreY - newHeight / 2,
    newWidth,
    newHeight,
  );
};

const zoomToPoint = (scaleFactor, x, y) => {
  const svgPoint = screenToSvgCoordinates(x, y);
  viewBox.value = [
    (viewBox.value[0] - svgPoint.x) * scaleFactor + svgPoint.x,
    (viewBox.value[1] - svgPoint.y) * scaleFactor + svgPoint.y,
    Math.max(viewBox.value[2] * scaleFactor, 1),
    Math.max(viewBox.value[3] * scaleFactor, 1),
  ];
};

const zoom = (zoomFactor) => {
  const newWidth = viewBox.value[2] * zoomFactor;
  const newHeight = viewBox.value[3] * zoomFactor;

  const newX = viewBox.value[0] + ((viewBox.value[2] - newWidth) / 2);
  const newY = viewBox.value[1] + ((viewBox.value[3] - newHeight) / 2);

  animateTransition(newX, newY, newWidth, newHeight, 500);
};

const pan = (x, y) => {
  const ctm = svg.value.getScreenCTM();

  const panned = new DOMMatrix([0, 0, x, y, 0, 0]).multiply(ctm.inverse());

  viewBox.value[0] += panned.c - panned.a;
  viewBox.value[1] += panned.d - panned.b;
};

const resize = (movementX, movementY, emitEvent) => {
  const ctm = svg.value.getScreenCTM();
  const movement = new DOMMatrix([0, 0, movementX, movementY, 0, 0]).multiply(ctm.inverse());

  const newDimensions = {
    x: props.selectedLocation.x,
    y: props.selectedLocation.y,
    width: props.selectedLocation.width,
    depth: props.selectedLocation.depth,
  };

  if (isResizing === 'up') {
    const y = props.selectedLocation.y - (movement.b - movement.d);
    const depth = props.selectedLocation.depth + (movement.b - movement.d);

    if (depth > 1) {
      newDimensions.y = y;
      newDimensions.depth = depth;
      emit(emitEvent, newDimensions);
    }
    return;
  }

  if (isResizing === 'right') {
    const width = props.selectedLocation.width + movement.c - movement.a;

    if (width > 1) {
      newDimensions.width = width;
      emit(emitEvent, newDimensions);
    }
    return;
  }

  if (isResizing === 'down') {
    const depth = props.selectedLocation.depth + movement.d - movement.b;

    if (depth > 1) {
      newDimensions.depth = depth;
      emit(emitEvent, newDimensions);
    }
    return;
  }

  if (isResizing === 'left') {
    const x = props.selectedLocation.x - (movement.a - movement.c);
    const width = props.selectedLocation.width + (movement.a - movement.c);

    if (width > 1) {
      newDimensions.x = x;
      newDimensions.width = width;
      emit(emitEvent, newDimensions);
    }
  }
};

const handleMove = (deltaX, deltaY) => {
  if (isPinching) return;

  if (isResizing) {
    resize(deltaX, deltaY, 'locationResize');
  } else if (!isDragging && !isPathNodeMoving) {
    isPanning = true;
    pan(-deltaX, -deltaY);
  } else if (isDragging && props.lockSelected && props.selectedLocation) {
    moveSelected(deltaX, deltaY);
  } else if (isPathNodeMoving && props.lockSelected && props.selectedPath) {
    moveSelectedPathNode(deltaX, deltaY);
  }
};

const handleEnd = (event, deltaX = 0, deltaY = 0) => {
  if (isResizing) {
    event.preventDefault();
    resize(deltaX, deltaY, 'locationResized');
    isResizing = false;
  } else if (isDragging) {
    emit('locationMoved', { x: props.selectedLocation.x, y: props.selectedLocation.y });
    isDragging = false;
  } else if (isPathNodeMoving) {
    emit('pathNodeMoved');
    isPathNodeMoving = false;
  }
  isPanning = false;
};

const handlePointer = (event, eventType) => {
  // eslint-disable-next-line default-case
  switch (eventType) {
    case 'down':
      emit('svgClicked');
      break;
    case 'move':
      if (event.buttons !== 0) {
        handleMove(event.movementX, event.movementY);
      }
      break;
    case 'up':
      handleEnd(event, event.movementX, event.movementY);
      break;
  }
};

// Pinch to zoom handling
const applyViewBox = (newViewBox) => {
  viewBox.value = newViewBox.map((v, i) => (i >= 2 && v < 1 ? 1 : v));
};

const pinchToZoom = (event) => {
  const [touch1, touch2] = event.touches;
  const dx = touch2.clientX - touch1.clientX;
  const dy = touch2.clientY - touch1.clientY;
  const distance = Math.sqrt(dx * dx + dy * dy);

  if (!initialDistance) {
    initialDistance = distance;
    initialViewBoxState = [...viewBox.value];
    return;
  }

  const scale = (distance / initialDistance) ** zoomSensitivity;
  const newWidth = Math.max(
    Math.min(initialViewBoxState[2] / scale, floorplan.value.width * maxZoom),
    floorplan.value.width * minZoom,
  );

  const newHeight = newWidth * (containerHeight.value / containerWidth.value);
  const centreX = initialViewBoxState[0] + initialViewBoxState[2] / 2;
  const centreY = initialViewBoxState[1] + initialViewBoxState[3] / 2;

  const newViewBox = [
    centreX - newWidth / 2,
    centreY - newHeight / 2,
    newWidth,
    newHeight,
  ];

  cancelAnimationFrame(animationFrameId);

  animationFrameId = window.requestAnimationFrame(() => applyViewBox(newViewBox));
};

const initializeEventListeners = () => {
  ['dblclick', 'tap'].forEach((eventType) => {
    useEventListener(selectedLocationEl, eventType, () => {
      if (!props.lockSelected) emit('locationDblClicked', props.selectedLocation);
    });

    useEventListener(selectedPathEl, eventType, () => {
      if (!props.lockSelected) emit('pathDblClicked', props.selectedPath);
    });
  });

  useEventListener(svg, 'wheel', (event) => {
    zoomToPoint(1 + 0.01 * event.deltaY, event.clientX, event.clientY);
    event.preventDefault();
  });

  useEventListener(topHandle, 'pointerdown', () => { isResizing = 'up'; });
  useEventListener(rightHandle, 'pointerdown', () => { isResizing = 'right'; });
  useEventListener(bottomHandle, 'pointerdown', () => { isResizing = 'down'; });
  useEventListener(leftHandle, 'pointerdown', () => { isResizing = 'left'; });

  useEventListener(pathHandle1, 'pointerdown', () => { isPathNodeMoving = 'first'; });
  useEventListener(pathHandle2, 'pointerdown', () => { isPathNodeMoving = 'second'; });

  useEventListener(selectedLocationEl, 'pointerdown', () => { if (props.lockSelected) isDragging = true; });

  useEventListener(svg, 'touchstart', (event) => {
    if (event.touches.length === 1 && !isPinching) {
      clearTimeout(touchTimeout);
      isLongPress = false;
      touchTimeout = setTimeout(() => { isLongPress = true; }, 500);
      initialTouchX = event.touches[0].clientX;
      initialTouchY = event.touches[0].clientY;
    } else if (event.touches.length === 2) {
      event.preventDefault();
      isPinching = true;
    }
  }, { passive: false });

  useEventListener(svg, 'touchmove', (event) => {
    if (event.touches.length === 1) {
      const touch = event.touches[0];
      const deltaX = initialTouchX - touch.clientX; // Calculate deltas for touch
      const deltaY = initialTouchY - touch.clientY;
      initialTouchX = touch.clientX; // Update initial touch positions
      initialTouchY = touch.clientY;
      handleMove(-deltaX, -deltaY); // Pass inverted deltas
    } else if (event.touches.length === 2) {
      pinchToZoom(event);
    }
  }, { passive: false });

  useEventListener(svg, 'touchend', (event) => {
    clearTimeout(touchTimeout);
    if (!isLongPress) emit('svgClicked');
    handleEnd(event);
    initialDistance = null;
    setTimeout(() => {
      isPinching = false;
    }, 100);
  });

  ['pointerdown', 'pointermove', 'pointerup', 'pointercancel'].forEach((eventType) => {
    useEventListener(svg, eventType, (event) => handlePointer(event, eventType.slice(7)), { passive: true });
  });

  if (props.showCoords) {
    useEventListener(svg, 'pointermove', ({ clientX, clientY }) => {
      const { x, y } = screenToSvgCoordinates(clientX, clientY);
      svgX.value = x;
      svgY.value = y;
    });
  }

  useEventListener(svg, 'contextmenu', (event) => {
    event.preventDefault();
    const { x, y } = screenToSvgCoordinates(event.clientX, event.clientY);
    emit('contentMenuOpened', {
      x, y, offsetX: event.offsetX, offsetY: event.offsetY,
    });
  });
};

watch(containerWidth, (newVal, oldVal) => {
  if (!initW) {
    initW = true;
  } else {
    viewBox.value[2] *= Math.max(newVal, 1) / Math.max(oldVal, 1);
  }
});

watch(containerHeight, (newVal, oldVal) => {
  if (!initH) {
    initH = true;
  } else {
    viewBox.value[3] *= Math.max(newVal, 1) / Math.max(oldVal, 1);
  }
});

onMounted(() => {
  resetViewBox(false);
  emit('svgReady');
  initializeEventListeners();
});

async function download() {
  await nextTick();

  const blob = new Blob(
    [svg.value.outerHTML],
    { type: 'text/svg' },
  );
  const filename = 'floorplan.svg';

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

defineExpose({
  download,
  zoomToLocation,
  resetViewBox,
  zoom,
  zoomToExtents,
});
</script>

<style>

.floorplan {
  position: relative;
  background-color: #F8FAFC;
  width:100%;
  height: 100%;
  max-width:100%;
}

.floorplan__coords {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 1rem;
  padding: 0.5rem;
  min-width: 10rem;
  text-align: center;
  font-size: 12px;
  z-index: 100;
  color: rgb(15 23 42 / 1);
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  border-radius: 0.25rem;
}

.fe-flash-selected {
  fill: var(--brand-primary-color);

  /* @keyframes duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
  animation: feFlash 0.8s ease-in 2s infinite alternate-reverse;
}

.fe-selected-text {
  fill: var(--system-white);
}

@keyframes feFlash {
  to {
    fill: var(--brand-primary-color-dark);
  }
}

.heatmap-enter-active,
.heatmap-leave-active {
  transition: all 0.5s ease;
}
.heatmap-enter-from,
.heatmap-leave-to {
  opacity: 0;
}

</style>
